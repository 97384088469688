import React, {useEffect, useState} from 'react';
import TabLayout from '@/pages/MainPage/components/FrameLayout';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import Page from '@/script/Page';
import StateData from '@/pages/MainPage/datas/StateData';
import OptData from '@/pages/MainPage/datas/OptData';
import EditableTableExt from "@/pages/MainPage/components/EditableTableExt";
import Table from '@/components/Table/Table.jsx';
import usePermission from "@/hooks/usePermission.mjs";


// 地图操作日志
const Frame = ({id, code, type, prjId, sVer}) => {
    const http = useHTTP();

    // 数据模型
    const [optLogs, setOptLogs] = useState([]);
    const [columns, setColumns] = useState([]);

    // 加载显示
    const [loading, setLoading] = useState(false);

    // 更新权限
    const hasUpt = usePermission([type + '_UPT'], [prjId], []);

    // 组件初始化
    useEffect(() => {
        setColumns(optColumns)
        doSearch();
    }, []);

    const doSearch = async () => {
        try {
            setLoading(true);

            const data = await runAsync(
                async () => {
                    return await http.get(`/basmapoptlogs`, {dataId: id, dataCode: code, dataType: type, prjId});
                }, {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                });

            setOptLogs(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 保存备注
    const doSave = async (optLog) => {
        try {
            setLoading(true);

            const data = await runAsync(
                async () => {
                    return await http.put(`/basmapoptlogs/${optLog.pkid}`, optLog);
                }, {
                    error: {content: (err) => `保存失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                });

            //setOptLogs(data);
        } catch (err) {
            console.log(err);
            // 有异常重新加载日志
            doSearch();
        } finally {
            setLoading(false);
        }
    }

    const optColumns = [
        {
            title: '操作时间',
            dataIndex: 'crtTime',
            width: 180,
        },
        {
            title: '操作人',
            dataIndex: 'crtName',
            width: 120,
        },
        {
            title: '分支号',
            dataIndex: 'sVer',
            align: 'center',
            width: 80,
        },
        {
            title: '版本号',
            dataIndex: 'dataVer',
            align: 'center',
            width: 80,
        },
        {
            title: '操作',
            dataIndex: 'optType',
            align: 'center',
            width: 160,
            render: (text, record, index) => {
                return <OptData value={text}/>;
            }
        },
        {
            title: '状态',
            dataIndex: 'dataStsCode',
            align: 'center',
            width: 160,
            render: (text, record, index) => {
                return <StateData value={text}/>;
            }
        },
        {
            title: '备注',
            dataIndex: 'memo',
            editable: hasUpt? true : false,
        },
    ];


    // 处理table 回调
    const doCallData = (row) => {
        const newData = [...optLogs];
        const index = newData.findIndex((item) => row.pkid === item.pkid);
        const item = newData[index];
        // 异步保存
        doSave({ ...item, ...row });

        newData.splice(index, 1, { ...item, ...row });
        setOptLogs(newData);
    }

    return (
        <TabLayout>
            <TabLayout.Header title="操作日志"></TabLayout.Header>

            <TabLayout.Content>
                <div className="smstable">
                    <div className="smstable-body" style={{paddingTop:"16px"}}>
                        {/* 数据区 */}
                        <EditableTableExt
                            doCallData={doCallData} rows={optLogs} columnss={columns}
                            loading={loading}
                            pagination={Page.init(optLogs)}
                        />
                    </div>
                </div>
            </TabLayout.Content>
        </TabLayout>
    );
}

export default Frame;
