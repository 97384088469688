import { Tag } from 'antd';

const OptData = ({ value }) => {
    switch (value) {
        case "ADD":
            return <Tag color="#DCDCDC">新增</Tag>;
        case "UPDATE":
            return <Tag color="blue">更新</Tag>;
        case "REVISE":
            return <Tag color="blue">修订</Tag>;
        case "RLS":
            return <Tag color="green">发布</Tag>;
        case "DISCARD":
            return <Tag color="red">废弃</Tag>;
        case "DELETE":
            return <Tag color="red">删除</Tag>;
    }
    return <div>{value}</div>;
}

export default OptData;